import Vue from 'vue';
import VueRouter from 'vue-router';
import apolloProvider from '@/apollo';
import { getToken, onLogout } from '@/vue-apollo';
import Login from '@/views/Login.vue';

Vue.use(VueRouter);

// const wsEndpoint = process.env.VUE_APP_GRAPHQL_WS || 'http://localhost:4040';
const apollo = apolloProvider.defaultClient;

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      forVisitors: true,
    },
    alias: '/login',
  },
  {
    path: '/dashboard',
    name: 'Tablero',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/send-templates',
    name: 'Envío de Templates',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/SendTemplates.vue'),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/notifications',
    name: 'Notificaciones',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Notification.vue'),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/campaigns',
    name: 'Campañas',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Campaigns.vue'),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/campaigns/:campaign_id/settings',
    name: 'Configuración de campaña',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/CampaignsSettings.vue'),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/campaigns/:campaign_id/assign-users',
    name: 'Asignar usuarios',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/CampaignsAssignUsers.vue'),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/team',
    name: 'team',
    component: () => import(/* webpackChunkName: "team" */ '../views/Team.vue'),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import(/* webpackChunkName: "configuration" */ '../views/Configuration.vue'),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/styleguide',
    name: 'Style Guide',
    component: () => import(/* webpackChunkName: "configuration" */ '../views/Styleguide.vue'),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "configuration" */ '../views/PageNotFound.vue'),
    meta: {
      forAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === 'login' && to.query.data) {
    onLogout(apollo);
  }

  const isAuthenticated = !!getToken();

  if (to.matched.some(record => record.meta.forVisitors)) {
    if (isAuthenticated) {
      next('/dashboard');
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.forAuth)) {
    if (!isAuthenticated) {
      next('/login');
    } else {
      next();
    }
  }
});

export default router;
