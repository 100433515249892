import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { getToken } from '@/vue-apollo';

Vue.use(VueAxios, axios);

const baseUrl = process.env.VUE_APP_API_BASE_URL ?? 'http://localhost:4040';

const API = axios.create({
  baseURL: `${baseUrl}/api/v1`,
});

export default {
  loginLegacy: params => API.post('/login/legacy', params),
  templateSend: params => API.post(
    '/messages/template/send',
    params,
    { headers: { Authorization: `Bearer ${getToken()}` } },
  ),
  downloadReport: (reportName, params) => axios({
    url: `${baseUrl}/api/v1/reports/${reportName}`,
    method: 'GET',
    responseType: 'blob',
    headers: { Authorization: `Bearer ${getToken()}` },
    params,
  }),
};
