import Vue from 'vue';

import AccountMultipleIcon from 'vue-material-design-icons/AccountMultiple.vue';
import AccountSupervisorCircleIcon from 'vue-material-design-icons/AccountSupervisorCircle.vue';
import BullhornVue from 'vue-material-design-icons/Bullhorn.vue';
import CalendarTodayIcon from 'vue-material-design-icons/CalendarToday.vue';
import CancelIcon from 'vue-material-design-icons/Cancel.vue';
import CheckIcon from 'vue-material-design-icons/Check.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import ClockOutlineIcon from 'vue-material-design-icons/ClockOutline.vue';
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import DeleteIcon from 'vue-material-design-icons/Delete.vue';
import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical.vue';
import DownloadIcon from 'vue-material-design-icons/Download.vue';
import EyeIcon from 'vue-material-design-icons/Eye.vue';
import FileIcon from 'vue-material-design-icons/File.vue';
import FilterVariantIcon from 'vue-material-design-icons/FilterVariant.vue';
import ForumIcon from 'vue-material-design-icons/Forum.vue';
import HistoryIcon from 'vue-material-design-icons/History.vue';
import ImageIcon from 'vue-material-design-icons/Image.vue';
import ImageSearchOutlineIcon from 'vue-material-design-icons/ImageSearchOutline.vue';
import LabelIcon from 'vue-material-design-icons/Label.vue';
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import MapMarkerIcon from 'vue-material-design-icons/MapMarker.vue';
import MessageIcon from 'vue-material-design-icons/Message.vue';
import MessageTextIcon from 'vue-material-design-icons/MessageText.vue';
import OpenInNewIcon from 'vue-material-design-icons/OpenInNew.vue';
import PaletteSwatchIcon from 'vue-material-design-icons/PaletteSwatch.vue';
import PencilIcon from 'vue-material-design-icons/Pencil.vue';
import PlusCircleIcon from 'vue-material-design-icons/PlusCircle.vue';
import SettingsIcon from 'vue-material-design-icons/Settings.vue';
import TextIcon from 'vue-material-design-icons/Text.vue';
import TextSubjectIcon from 'vue-material-design-icons/TextSubject.vue';
import ToolsIcon from 'vue-material-design-icons/Tools.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
import VideoIcon from 'vue-material-design-icons/Video.vue';
import ViewDashboardIcon from 'vue-material-design-icons/ViewDashboard.vue';
import ViewHeadlineIcon from 'vue-material-design-icons/ViewHeadline.vue';
import VoiceIcon from 'vue-material-design-icons/Voice.vue';
import WrenchIcon from 'vue-material-design-icons/Wrench.vue';
import SendCircleOutlineIcon from 'vue-material-design-icons/SendCircleOutline.vue';
import Whatsapp from 'vue-material-design-icons/Whatsapp.vue';
import CheckCircleOutlineIcon from 'vue-material-design-icons/CheckCircleOutline.vue';
import CheckCircleIcon from 'vue-material-design-icons/CheckCircle.vue';
import BellOutlineIcon from 'vue-material-design-icons/BellOutline.vue';

Vue.component('account-multiple-icon', AccountMultipleIcon);
Vue.component('account-supervisor-circle-icon', AccountSupervisorCircleIcon);
Vue.component('bullhorn-icon', BullhornVue);
Vue.component('calendar-today-icon', CalendarTodayIcon);
Vue.component('cancel-icon', CancelIcon);
Vue.component('check-icon', CheckIcon);
Vue.component('chevron-left-icon', ChevronLeftIcon);
Vue.component('chevron-right-icon', ChevronRightIcon);
Vue.component('clock-outline-icon', ClockOutlineIcon);
Vue.component('close-circle-icon', CloseCircleIcon);
Vue.component('close-icon', CloseIcon);
Vue.component('delete-icon', DeleteIcon);
Vue.component('dots-vertical-icon', DotsVerticalIcon);
Vue.component('download-icon', DownloadIcon);
Vue.component('eye-icon', EyeIcon);
Vue.component('file-icon', FileIcon);
Vue.component('filter-variant-icon', FilterVariantIcon);
Vue.component('forum-icon', ForumIcon);
Vue.component('history-icon', HistoryIcon);
Vue.component('image-icon', ImageIcon);
Vue.component('image-search-outline-icon', ImageSearchOutlineIcon);
Vue.component('label-icon', LabelIcon);
Vue.component('magnify-icon', MagnifyIcon);
Vue.component('map-marker-icon', MapMarkerIcon);
Vue.component('message-icon', MessageIcon);
Vue.component('message-text-icon', MessageTextIcon);
Vue.component('open-in-new-icon', OpenInNewIcon);
Vue.component('palette-swatch-icon', PaletteSwatchIcon);
Vue.component('pencil-icon', PencilIcon);
Vue.component('plus-circle-icon', PlusCircleIcon);
Vue.component('settings-icon', SettingsIcon);
Vue.component('text-icon', TextIcon);
Vue.component('text-subject-icon', TextSubjectIcon);
Vue.component('tools-icon', ToolsIcon);
Vue.component('trash-can-icon', TrashCanIcon);
Vue.component('video-icon', VideoIcon);
Vue.component('view-dashboard-icon', ViewDashboardIcon);
Vue.component('view-headline-icon', ViewHeadlineIcon);
Vue.component('voice-icon', VoiceIcon);
Vue.component('wrench-icon', WrenchIcon);
Vue.component('send-circle-outline', SendCircleOutlineIcon);
Vue.component('whatsapp', Whatsapp);
Vue.component('check-circle-outline', CheckCircleOutlineIcon);
Vue.component('check-circle', CheckCircleIcon);
Vue.component('bell-outline', BellOutlineIcon);
