<template>
  <div>{{ $t('login.title') }}</div>
</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/api';
import { onLogin } from '@/vue-apollo';

const redirectUrl = process.env.VUE_APP_REDIRECT_URL ?? 'https://app.heycenter.com';

export default {
  data() {
    return {
      today: this.$moment(new Date())
        .format(),
    };
  },
  mounted() {
    this.getInit();
  },
  methods: {
    ...mapMutations({
      updateAccessToken: 'dashboard/updateAccessToken',
    }),
    getInit() {
      const { data } = this.$route.query;

      if (data) {
        this.getToken({
          data,
        });
      } else {
        window.location.href = redirectUrl;
      }
    },
    getToken(data) {
      api.loginLegacy(data)
        .then((res) => {
          if (res.data && res.data.token) {
            this.updateAccessToken(res.data.token);
            return onLogin(this.$apollo.provider.defaultClient, res.data.token);
          }
          throw new Error('Cannot set token');
        })
        .then(() => this.$router.push('/dashboard'))
        .catch((e) => {
          console.log('error', e);
          window.location.href = redirectUrl;
        });
    },
  },
};
</script>
