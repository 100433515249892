export default {
  apiKey: 'AIzaSyAL4cEBKTGNRFh7ll50sFftvpYYVDrH4Sw',
  authDomain: 'hey-admin-8bb93.firebaseapp.com',
  databaseURL: 'https://hey-admin-8bb93.firebaseio.com',
  projectId: 'hey-admin-8bb93',
  storageBucket: 'hey-admin-8bb93.appspot.com',
  messagingSenderId: '459975840584',
  appId: '1:459975840584:web:86554373b80a98a826197d',
  measurementId: 'G-SSSQMRWPJP',
};
