/* eslint-disable import/prefer-default-export */
import { getDataNotification } from '@/utils/helper';


export const notification = {
  namespaced: true,
  state: {
    toogleMenu: false,
    notifications: [],
    details: [],
    news: null,
  },
  getters: {
    getNotifications(state) {
      return state.notifications.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      );
    },
    getNews(state) {
      let count = 0;
      state.news = count;
      state.notifications.forEach((item) => {
        if (!item.readedAt) {
          count += 1;
          if (count > 9) {
            state.news = '+9';
          } else {
            state.news = count;
          }
        }
      });
      return state.news;
    },
  },
  mutations: {
    setToogleMenu(state, payload) {
      state.toogleMenu = payload;
    },
    setNotifications(state, payload) {
      const items = payload.map((item) => {
        if (item.action && item.action.type === 'show-detail') {
          return getDataNotification(item);
        }
        return item;
      });
      state.notifications = items;
    },
    markAsRead(state, payload) {
      let data = state.notifications[payload];
      data = Object.assign({}, data, { readedAt: Date.now() });
      state.notifications.splice(payload, 1, data);
    },
    setDetails(state, payload) {
      state.details = payload;
    },
  },
};
