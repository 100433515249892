import Vue from 'vue';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import momentDurationFormat from 'moment-duration-format';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import Tooltip from 'vue-directive-tooltip';
import VueSweetalert2 from 'vue-sweetalert2';
import * as firebase from 'firebase/app';
import firebaseConfig from '@/firebase-config';
import App from './App.vue';
import apolloProvider from './apollo';
import router from './router';
import store from './store';
import i18n from './i18n';
import './icons';
import 'firebase/firestore';
import 'firebase/analytics';

moment.locale('es');
momentDurationFormat(moment);

Vue.filter('numFormat', numFormat(numeral));

Vue.use(VueMoment, {
  moment,
});

Vue.use(Tooltip);
Vue.use(VueSweetalert2);

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false;

Vue.prototype.$analytics = firebase.analytics();

new Vue({
  router,
  store,
  i18n,
  apolloProvider,
  render: h => h(App),
}).$mount('#app');
