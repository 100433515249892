const chartColors = [
  '#F56565',
  '#ED8936',
  '#ECC94B',
  '#48BB78',
  '#38B2AC',
  '#4299E1',
  '#667EEA',
  '#9F7AEA',
  '#FC8181',
  '#F6AD55',
  '#F6E05E',
  '#68D391',
  '#4FD1C5',
  '#63B3ED',
  '#7F9CF5',
  '#B794F4',
  '#FEB2B2',
  '#FBD38D',
  '#FAF089',
  '#9AE6B4',
  '#81E6D9',
  '#90CDF4',
  '#A3BFFA',
  '#D6BCFA',
  '#FED7D7',
  '#FEEBC8',
  '#FEFCBF',
  '#C6F6D5',
  '#B2F5EA',
  '#BEE3F8',
  '#C3DAFE',
  '#E9D8FD',
];

function getDisplayableFormat(format) {
  switch (format) {
    case 'YYYY-MM-DD':
      return 'DD MMM YYYY';
    case 'YYYY-MM':
      return 'MMM YYYY';
    case 'YYYY':
      return 'YYYY';
    case 'HH':
      return 'hh:mm A';
    case 'E':
      return 'ddd';
    case 'MM':
      return 'MMM';
    default:
      return format;
  }
}

function getDataNotification(item) {
  const data = Object.assign({}, item);
  let status = 'En proceso';
  let msg = `Numeros enviados: ${item.message}`;
  const errors = item.detail.filter(detail => detail.data && detail.data.status !== 'sended');
  if (parseInt(item.message, 10) === item.detail.length) {
    status = 'Enviado';
  }
  if (errors && errors.length) {
    msg += ` <br>Errores: ${errors.length}`;
  } else {
    msg += ' <br>Errores: 0';
  }
  data.status = status;
  data.message = msg;
  return data;
}

export { getDisplayableFormat, chartColors, getDataNotification };
