/* eslint-disable import/prefer-default-export */
export const setting = {
  namespaced: true,
  state: {
    channels: [
      {
        id: 1,
        name: 'Whastapp',
      },
      {
        id: 2,
        name: 'Telegram',
      },
      {
        id: 3,
        name: 'Messenger',
      },
    ],
    agentsAssignList: [],
    currentCampaign: null,
  },
  getters: {
    getCurrentCampaign(state) {
      return state.currentCampaign;
    },
  },
  mutations: {
    setAgentsAssignList(state, payload) {
      state.agentsAssignList = payload;
    },
    deleteAssignUser(state, payload) {
      const index = state.agentsAssignList.findIndex(agent => agent.id === payload);
      if (index !== -1) {
        state.agentsAssignList.splice(index, 1);
      }
    },
    deleteAssignUsers(state) {
      state.agentsAssignList = [];
    },
    setCurrentCampaign(state, payload) {
      state.currentCampaign = payload;
    },
    clearCurrentCampaign(state) {
      state.currentCampaign = null;
    },
  },
};
