<template>
  <div id="app">
    <router-view v-if="isErrorPage"/>
    <site-navigation v-else/>
  </div>
</template>

<script>
export default {
  components: {
    SiteNavigation: () => import('@/components/SiteNavigation.vue'),
  },
  computed: {
    isErrorPage() {
      return this.$route.name === '404';
    },
  },
};
</script>

<style lang="scss">
  @import '~styles/_app.scss';
</style>
